@import url(https://fonts.googleapis.com/css2?family=Jost:wght@400;500;700&family=Quicksand:wght@400;600;700&display=swap);
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
    outline: none;
}

html {
    scroll-behavior: smooth;
}

body {
    color: #333;
    font-family: 'Jost', sans-serif;
    font-weight: 400;
}

body div {
    overflow: hidden;
}

h1, h2, h3, h4 {
    font-family: 'Quicksand', sans-serif;
}

a {
    text-decoration: none;
    transition: 0.4s;
}

p {
    font-size: 20px;
}

.mt-100 {
    margin-top: 100px;
}
.mr-15 {
    margin-right: 15px;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.container {
    max-width: 1200px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}


@media (min-width: 1200px) {
    .col-xl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media (min-width: 992px) {
    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}
@media (min-width: 768px) {
    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}
@media (min-width: 576px) {
    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

.floatleft {
    float: left;
}

.floatright {
    float: right;
}


@media(max-width: 767px){
    .floatleft {
        float: none;
    }
    
    .floatright {
        float: none;
    }
}

.centered {
    text-align: center;
}

.button {
    cursor: pointer;
    padding: 16px 32px;
    font-weight: 700;
    border: 0;
    border-radius: 4px;
    background: #ffca00;
    color: #333;
    font-size: 14px;
    text-transform: uppercase;
    display: inline-block;
}

.button:hover {
    opacity: 0.8;
}
.Header_header__3FBoS {
    background: #FFF7DF;
    padding: 15px 0;
    position: fixed;
    width: 100%;
    z-index: 999;
}

.Header_logo__3W9di {
    width: 50%;
    float: left;
    display: flex;
    height: 70px;
    align-items: center;
}

.Header_logo__3W9di img {
    height: 55px;
    width: auto;
}

.Header_navigation__2QHlq {
    width: 50%;
    float: left;
    display: flex;
    justify-content: flex-end;
    height: 70px;
    align-items: center;
}

.Header_navigation__2QHlq ul {
    list-style: none;
}

.Header_navigation__2QHlq ul li {
    display: inline-block;
}

.Header_navigation__2QHlq ul li a {
    color: #333;
    font-weight: 500;
}

.Header_navigation__2QHlq ul li a:hover {
    color: #FFCA00;
}

.Header_navigation__2QHlq ul li a.Header_button__34Y3N {
    padding: 10px 20px;
    border-radius: 4px;
    background: #FFCA00;
    margin-left: 20px;
    color: #333;
}

.Header_navigation__2QHlq ul li a.Header_button__34Y3N:hover {
    opacity: 0.8;
}

@media(max-width: 400px){
    .Header_logo__3W9di {
        width: 35%;
    }
    .Header_logo__3W9di img {
        height: 35px;
    }
    .Header_navigation__2QHlq {
        width: 65%;
    }
    .Header_navigation__2QHlq ul li a.Header_button__34Y3N {
        margin-left: 10px;
    }
}
.Newslatter_newslatter__3m3Uy p {
    font-size: 12px;
    margin-top: 6px;
    font-weight: 400;
}
.Newslatter_newslatter__3m3Uy form {
    /* display: flex;
    flex-wrap: nowrap; */
    width: 480px;
}
.Newslatter_newslatter__3m3Uy form input[type="email"] {
    height: 55px;
    /* flex: 1 1 70%; */
    border: 2px solid;
    border-color:#ffca00;
    background: transparent;
    border-radius: 4px;
    color: #333;
    transition: 0.3s;
    padding: 0 15px;
    margin-right: 20px;
    font-size: 16px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
    width: 280px;
    float: left;
}
.Newslatter_newslatter__3m3Uy form .Newslatter_email__XXxEb:focus {
    opacity: 0.8;
}
.Newslatter_newslatter__3m3Uy form input[type="submit"] {
    height: 55px;
    /* flex: 1 1 30%; */
    background: #ffca00;
    color: #333;
    border: 2px solid;
    border-color: #ffca00;
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
    float: left;
}
.Newslatter_newslatter__3m3Uy form input[type="submit"]:hover {
    opacity: 0.8;
}



@media(max-width: 450px){
    .Newslatter_newslatter__3m3Uy form  {
        width: 100%;
        display: block;
    }
    .Newslatter_newslatter__3m3Uy input[type="email"]  {
        width: 100% !important;
        margin-right: 0 !important;
        margin-bottom: 15px;
        text-align: center;
    }
    .Newslatter_newslatter__3m3Uy input[type="submit"]  {
        width: 100%;
        float: none !important;
    }
    .Newslatter_clear__1G79c {
        overflow: visible !important;
        overflow: initial !important;
    }
}
.Contents_contentsection__QLv6M {
    background: #FFF7DF;
    padding: 120px 0;
}


@media(max-width: 767px){
    .Contents_content__OKWZi {
        margin-bottom: 40px;
    }
}

.Contents_content__OKWZi h1 {
    font-size: 66px;
    font-weight: 700;
    margin-bottom: 20px;
}

@media(max-width: 767px){
    .Contents_content__OKWZi h1 {
        font-size: 42px;
    }
}

.Contents_content__OKWZi h4 {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 30px;
}

.Contents_appscreen__3jUsF {
    position: relative;
    width: 300px;
}

@media(max-width: 767px){
    .Contents_appscreen__3jUsF {
        margin: auto;
    }
}

.Contents_frame__GtJVR {
    width: 300px;
    height: auto;
    z-index: 1;
    position: relative;
}

.Contents_screen__c-38Q {
    position: absolute;
    left: 20px;
    top: 20px;
    width: 260px;
    height: auto;
}
.Contents2_contentsection2__3_jWv {
    background: #F8F8F6;
    padding: 120px 0;
}

.Contents2_content__3ZDMR {
    height: 100%;
    display: flex;
    align-items: center;
}

.Contents2_content__3ZDMR h1 {
    font-size: 66px;
    font-weight: 700;
    margin-bottom: 20px;
}

@media(max-width: 767px){
    .Contents2_content__3ZDMR h1 {
        font-size: 42px;
    }
}
.Contents2_content__3ZDMR h4 {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 30px;
}

.Contents2_content__3ZDMR p {
    margin-bottom: 30px;
}

.Contents2_appscreen__2u9jw {
    position: relative;
    width: 300px;
}

@media(max-width: 767px){
    .Contents2_appscreen__2u9jw {
        margin: auto;
        margin-bottom: 40px;
    }
}

.Contents2_frame__2zkus {
    width: 300px;
    height: auto;
    z-index: 1;
    position: relative;
}

.Contents2_screen__3v0xf {
    position: absolute;
    left: 20px;
    top: 20px;
    width: 260px;
    height: auto;
}
.Contents3_contentsection3__1mSVp {
    background: #fff;
    padding: 120px 0;
}



.Contents3_content__3I_Bx {
    height: 100%;
    display: flex;
    align-items: center;
}

.Contents3_content__3I_Bx h1 {
    font-size: 66px;
    font-weight: 700;
    margin-bottom: 20px;
}
@media(max-width: 767px){
    .Contents3_content__3I_Bx h1 {
        font-size: 42px;
    }
}
.Contents3_content__3I_Bx h4 {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 30px;
}

.Contents3_content__3I_Bx p {
    margin-bottom: 30px;
}

.Contents3_appscreen__9IcyW {
    position: relative;
    width: 300px;
}

@media(max-width: 767px){
    .Contents3_appscreen__9IcyW {
        margin: auto;
        margin-top: 40px;
    }
}

.Contents3_frame__2kPNu {
    width: 300px;
    height: auto;
    z-index: 1;
    position: relative;
}

.Contents3_screen__2Y0EI {
    position: absolute;
    left: 20px;
    top: 20px;
    width: 260px;
    height: auto;
}
.Contents4_contentsection4__QJgZA {
    background: #fdd54a12;
    padding: 120px 0;
}

.Contents4_content__2laVz {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.Contents4_content__2laVz h1 {
    font-size: 66px;
    font-weight: 700;
    margin-bottom: 20px;
}
@media(max-width: 767px){
    .Contents4_content__2laVz h1 {
        font-size: 42px;
    }
}
.Contents4_content__2laVz h4 {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 30px;
}

.Contents4_content__2laVz p {
    margin-bottom: 30px;
}

.Contents4_content__2laVz a img {
    height: 55px;
    width: auto;
}

.Contents4_appscreen__21SV1 {
    position: relative;
    width: 300px;
}

@media(max-width: 767px){
    .Contents4_appscreen__21SV1 {
        margin: auto;
        margin-bottom: 40px;
    }
}

.Contents4_frame__11FTZ {
    width: 300px;
    height: auto;
    z-index: 1;
    position: relative;
}

.Contents4_screen__3YgUf {
    position: absolute;
    left: 20px;
    top: 20px;
    width: 260px;
    height: auto;
}
.Footer_footer__3wY5n {
    padding: 60px 0;
}

.Footer_footerlogo__WbMxr {
    margin-bottom: 20px;
    display: inline-block;
}

.Footer_footerlogo__WbMxr img {
    height: 55px;
    width: auto;
}

.Footer_social__3En7h {
    margin-bottom: 30px;
}

.Footer_social__3En7h a {
    height: 45px;
    width: 45px;
    line-height: 45px;
    font-size: 14px;
    text-align: center;
    background: #f3e8c9;
    color: #333;
    border-radius: 50%;
    margin: 0 6px;
    display: inline-block;
}

.Footer_social__3En7h a:hover {
    background: #333;
    color: #fff;
}

.Footer_footer__3wY5n p {
    font-size: 16px;
    font-weight: 400;
}
