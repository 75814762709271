@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;500;700&family=Quicksand:wght@400;600;700&display=swap');


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
    outline: none;
}

html {
    scroll-behavior: smooth;
}

body {
    color: #333;
    font-family: 'Jost', sans-serif;
    font-weight: 400;
}

body div {
    overflow: hidden;
}

h1, h2, h3, h4 {
    font-family: 'Quicksand', sans-serif;
}

a {
    text-decoration: none;
    transition: 0.4s;
}

p {
    font-size: 20px;
}

.mt-100 {
    margin-top: 100px;
}
.mr-15 {
    margin-right: 15px;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.container {
    max-width: 1200px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}


@media (min-width: 1200px) {
    .col-xl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media (min-width: 992px) {
    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}
@media (min-width: 768px) {
    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}
@media (min-width: 576px) {
    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

.floatleft {
    float: left;
}

.floatright {
    float: right;
}


@media(max-width: 767px){
    .floatleft {
        float: none;
    }
    
    .floatright {
        float: none;
    }
}

.centered {
    text-align: center;
}

.button {
    cursor: pointer;
    padding: 16px 32px;
    font-weight: 700;
    border: 0;
    border-radius: 4px;
    background: #ffca00;
    color: #333;
    font-size: 14px;
    text-transform: uppercase;
    display: inline-block;
}

.button:hover {
    opacity: 0.8;
}