.newslatter p {
    font-size: 12px;
    margin-top: 6px;
    font-weight: 400;
}
.newslatter form {
    /* display: flex;
    flex-wrap: nowrap; */
    width: 480px;
}
.newslatter form input[type="email"] {
    height: 55px;
    /* flex: 1 1 70%; */
    border: 2px solid;
    border-color:#ffca00;
    background: transparent;
    border-radius: 4px;
    color: #333;
    transition: 0.3s;
    padding: 0 15px;
    margin-right: 20px;
    font-size: 16px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
    width: 280px;
    float: left;
}
.newslatter form .email:focus {
    opacity: 0.8;
}
.newslatter form input[type="submit"] {
    height: 55px;
    /* flex: 1 1 30%; */
    background: #ffca00;
    color: #333;
    border: 2px solid;
    border-color: #ffca00;
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
    float: left;
}
.newslatter form input[type="submit"]:hover {
    opacity: 0.8;
}



@media(max-width: 450px){
    .newslatter form  {
        width: 100%;
        display: block;
    }
    .newslatter input[type="email"]  {
        width: 100% !important;
        margin-right: 0 !important;
        margin-bottom: 15px;
        text-align: center;
    }
    .newslatter input[type="submit"]  {
        width: 100%;
        float: none !important;
    }
    .clear {
        overflow: initial !important;
    }
}