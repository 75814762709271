.contentsection4 {
    background: #fdd54a12;
    padding: 120px 0;
}

.content {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.content h1 {
    font-size: 66px;
    font-weight: 700;
    margin-bottom: 20px;
}
@media(max-width: 767px){
    .content h1 {
        font-size: 42px;
    }
}
.content h4 {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 30px;
}

.content p {
    margin-bottom: 30px;
}

.content a img {
    height: 55px;
    width: auto;
}

.appscreen {
    position: relative;
    width: 300px;
}

@media(max-width: 767px){
    .appscreen {
        margin: auto;
        margin-bottom: 40px;
    }
}

.frame {
    width: 300px;
    height: auto;
    z-index: 1;
    position: relative;
}

.screen {
    position: absolute;
    left: 20px;
    top: 20px;
    width: 260px;
    height: auto;
}