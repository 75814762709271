.footer {
    padding: 60px 0;
}

.footerlogo {
    margin-bottom: 20px;
    display: inline-block;
}

.footerlogo img {
    height: 55px;
    width: auto;
}

.social {
    margin-bottom: 30px;
}

.social a {
    height: 45px;
    width: 45px;
    line-height: 45px;
    font-size: 14px;
    text-align: center;
    background: #f3e8c9;
    color: #333;
    border-radius: 50%;
    margin: 0 6px;
    display: inline-block;
}

.social a:hover {
    background: #333;
    color: #fff;
}

.footer p {
    font-size: 16px;
    font-weight: 400;
}